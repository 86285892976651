<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Terminal History</h1>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter mr-2">
          <p class="">Filter By:</p>
          <select
            required
            v-model="filterType"
            class="shadow h-8 appearance-none border rounded w-full px-2 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          >
            <option value="">Select Filter Type</option>
            <option value="assignment">Assignment History</option>
            <option value="unmap">Unmapped History</option>
          </select>
        </div>
        <div
          class="flex-2 inline-block time-filter"
          v-if="filterType === 'unmap'"
        >
          <p>Unmapped History:</p>
          <input
            type="text"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            placeholder="Terminal ID"
            v-model="unmappedHistory"
          />
        </div>
        <div
          class="flex-2 inline-block time-filter"
          v-if="filterType === 'assignment'"
        >
          <p>Assignment History:</p>
          <input
            type="text"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            placeholder="Terminal ID"
            v-model="assignmentHistory"
          />
        </div>

        <div class="flex mt-5">
          <button
            @click="fetchRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs h-10 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="mt-14">
        <div class="mt-5" style="max-height: 600px; overflow: auto">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="23"
                style="background: #dee6f2"
              >
                Terminal Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Terminal ID
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Serial Number
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                User ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Date created
              </th>
            </tr>
            <tr class="text-xs" v-for="(account, i) in history" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.terminal_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.serial_no || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.store_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.store_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.user_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.user_email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.user_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(account.created_at).format("DD MMM YYYY - h:mm:ss a")
                }}
              </td>
            </tr>
          </table>
        </div>
        <!-- <div class="mt-3">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
            v-if="history.length > 99"
          >
            Next &raquo;
          </button>
        </div> -->
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import authorizedUsers from "../config/authUsers";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      unmappedHistory: "",
      assignmentHistory: "",
      filterType: "",
      history: [],
      currentPage: 1,
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters(["GET_TERMINAL_HISTORY"]),
    filterAccounts() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      //   return this.GET_TERMINAL_HISTORY.data.filter((account) =>
      //     account.merchantName.includes(this.search)
      //   );
      // this.documents = filtered;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    //   this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      const historyType = this.filterType;
      const terminalid =
        historyType === "assignment"
          ? this.assignmentHistory
          : this.unmappedHistory;
      if (terminalid !== "") {
        this.isLoading = true;
        const payload = { historyType, terminalid };
        let response = await this.$store.dispatch(
          "FETCH_TERMINAL_HISTORY",
          payload
        );

        this.isLoading = false;
        if (response.data) {
          this.history = response.data.terminal_id_history;
        } else {
          alert(response.message);
        }
      }
    },

    clearFilter() {
      this.fetchRecords();
      this.filterType = "";
      this.unmappedHistory = "";
      this.assignmentHistory = "";
      this.history = [];
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let filterActive = this.filterActive;
      let filterType = this.filterType;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let payload = {
        page: currPage - 1,
        filterActive,
        filterType,
        date,
        searchActive,
        search,
      };
      let res = await this.$store.dispatch(
        "PAGINATE_SUPPORT_DEPLOYMENT",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
        if (res.data === null) {
          alert(res.message);
          this.fetchRecords();
        }
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let filterActive = this.filterActive;
      let filterType = this.filterType;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let payload = {
        page: currPage + 1,
        filterActive,
        filterType,
        date: this.dateData,
        searchActive,
        search,
      };
      let res = await this.$store.dispatch(
        "PAGINATE_SUPPORT_DEPLOYMENT",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
        if (res.data === null) {
          alert(res.message);
          this.fetchRecords();
        }
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
